<template>
  <div>
    <b-modal
      id="zalo-edit-modal"
      ref="zalo-edit-modal"
      title="Chỉnh sửa nhóm Zalo"
      size="l"
      :hide-header-close="true"
      :no-close-on-backdrop="true"
      @cancel="resetModal"
      @hide="resetModal"
    >
      <b-overlay>
        <b-container class="p-0">
          <form ref="form">
            <b-row>
              <b-col>
                <label for="group-name">
                  Tên nhóm <span style="color: red">*</span>
                </label>
                <b-form-input
                  id="group-name"
                  v-model="form.group_name"
                  placeholder="Nhập tên nhóm"
                  required
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <basic-select
                  label="Loại nhóm"
                  placeholder="--- Chọn loại nhóm ---"
                  track-by="value"
                  value-label="text"
                  :options="zaloTypes"
                  v-model="form.group_description"
                  required
                  changeValueByObject
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div
                  v-b-tooltip.hover="
                    'Bạn phải cần phải có quyền Admin OA để khi tạo nhóm có thể tham gia ngay vào group'
                  "
                >
                  <basic-select
                    label="Chỉ định Health Coach"
                    placeholder="Chỉ định health coach cho khóa"
                    track-by="id"
                    value-label="fullNameSearch"
                    name="coach"
                    v-model="coach_id"
                    :options="coachesList"
                    :multiple="true"
                    required
                  />
                </div>
              </b-col>
            </b-row>
            <b-col>
              <div>
                <b-button v-b-toggle.collapse1>
                  <img
                    src="/media/svg/icons/Code/Settings4.svg"
                    alt="Settings Icon"
                    class="icon"
                  />
                  <span class="ml-2">Cài đặt nâng cao</span>
                </b-button>
              </div>
            </b-col>

            <b-collapse id="collapse1">
              <!-- First Section: Lock Send Message -->
              <b-col>
                <b-row>
                  <b-col>
                    <div class="bold-label">
                      Cho phép thành viên trong nhóm nhắn tin
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <basic-radio
                      :labelCols="3"
                      inline
                      name="lock_send_msg"
                      v-model="form.lock_send_msg"
                      :options="radioOption1"
                    ></basic-radio>
                  </b-col>
                </b-row>
              </b-col>

              <!-- Second Section: Approval Mode for New Members -->
              <b-col>
                <b-row>
                  <b-col>
                    <div class="bold-label">
                      Cài đặt chế độ phê duyệt thành viên mới
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <basic-radio
                      :labelCols="3"
                      inline
                      name="join_appr"
                      v-model="form.join_appr"
                      :options="radioOption2"
                    ></basic-radio>
                  </b-col>
                </b-row>
              </b-col>

              <!-- Third Section: Enable Message History -->
              <b-col>
                <b-row>
                  <b-col>
                    <div class="bold-label">
                      Cho phép thành viên mới đọc tin nhắn gần nhất
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <basic-radio
                      :labelCols="3"
                      inline
                      name="enable_msg_history"
                      v-model="form.enable_msg_history"
                      :options="radioOption3"
                    ></basic-radio>
                  </b-col>
                </b-row>
              </b-col>

              <!-- Fourth Section: Enable Link Join -->
              <b-col>
                <b-row>
                  <b-col>
                    <div class="bold-label">
                      Cho phép tham gia nhóm bằng link
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <basic-radio
                      :labelCols="3"
                      inline
                      name="enable_link_join"
                      v-model="form.enable_link_join"
                      :options="radioOption4"
                    ></basic-radio>
                  </b-col>
                </b-row>
              </b-col>
            </b-collapse>
            <br />
          </form>
        </b-container>
      </b-overlay>
      <template #modal-footer>
        <div class="w-100 d-flex align-items-center justify-content-end">
          <b-button
            class="btn btn-plain ml-2"
            href="#"
            @click.stop="handleCancel"
            tabindex="0"
          >
            Thoát
          </b-button>

          <b-button
            class="btn btn-success ml-2"
            href="#"
            @click.stop="handleSubmit"
            tabindex="0"
            :disabled="isSubmitting"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
            </span>
            Cập nhật
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  components: {},
  name: 'ZaloEditModal',
  props: {
    groupItem: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      zaloGroupDetail: [],
      data: '',
      selectedPackage: '',
      form: {
        group_name: '',
        group_description: [],
        asset_id: '',
        member_user_ids: [],
        lock_send_msg: true,
        join_appr: false,
        enable_msg_history: true,
        enable_link_join: true,
        group_avatar: '',
      },

      zaloTypes: [
        { value: 'common', text: 'Nhóm Chung' },
        { value: 'private', text: 'Nhóm HealthCoach' },
      ],
      radioOption1: [
        { name: 'Không cho phép', value: true },
        { name: 'Cho phép', value: false },
      ],
      radioOption2: [
        { name: 'Không cần duyệt', value: false },
        { name: 'Phải duyệt', value: true },
      ],
      radioOption3: [
        { name: 'Không cho phép xem', value: false },
        { name: 'Cho phép xem', value: true },
      ],
      radioOption4: [
        { name: 'Không cho phép', value: false },
        { name: 'Cho phép', value: true },
      ],
      filterStatusAvailabel: [],
      filterStatus: [],
      coachesList: [],
      coach_id: '',
      lockmessage: '',
      member_user_ids: new Set(),
      member_user_name: new Set(),
      isSubmitting: false,
    };
  },
  watch: {
    groupItem(newVal) {
      if (newVal.name.trim().length > 1) {
        this.form.group_name = newVal.name;
        if (newVal.group_description.includes('Chung')) {
          this.form.group_description = { value: 'common', text: 'Nhóm Chung' };
        } else {
          this.form.group_description = {
            value: 'private',
            text: 'Nhóm HealthCoach',
          };
        }
        this.updateCoachId(newVal);
        this.updateMemberUserIds();
        this.lockmessage = this.form.group_description.value;
      }
    },

    coach_id: {
      handler() {
        this.updateMemberUserIds();
        this.lockmessage = this.form.group_description.value;
      },
      immediate: true,
      deep: true,
    },
  },
  async created() {
    await this.getCoachList();
  },

  methods: {
    extractNumbers(str) {
      const regex = /\d+/g; 
      return str.match(regex) ? str.match(regex) : [];
    },

    updateCoachId(newVal) {
      const coachNumbers = this.extractNumbers(newVal.group_description);
      this.coach_id = this.coachesList
        .filter((coach) => {
          const isMatch = coachNumbers.includes(coach.zaloUserId); 
          return isMatch;
        })
        .map((coach) => ({
          id: coach.id,
          fullNameSearch: coach.fullNameSearch,
          zaloUserId: coach.zaloUserId,
        }));
    },

    updateMemberUserIds() {
      this.member_user_ids.clear();
      this.member_user_name.clear();

      if (this.coach_id && Array.isArray(this.coach_id)) {
        this.coach_id.forEach((coachId) => {
          const results = this.coachesList.filter(
            (filter) => filter.id == coachId,
          );

          results.forEach((result) => {
            if (result && result.zaloUserId) {
              this.member_user_ids.add(result.zaloUserId);
              this.member_user_name.add(result.fullNameSearch);
            }
          });
        });
      }
    },

    async getCoachList() {
      const coachList = await this.$api.get('/Account/ZaloIdCoachs');
      this.coachesList = coachList.data;
    },
    changeText(text) {
      if (text === 'common') {
        return 'Nhóm Chung';
      } else if (text === 'private') {
        return 'Nhóm HealthCoach';
      }
    },

    copyToClipboard() {
      navigator.clipboard
        .writeText(this.data.group_link)
        .then(() => {
          this.$toastr.s({
            title: 'Thành công',
            msg: 'Đã sao chép link nhóm',
          });
        })
        .catch((err) => {
          console.error('Failed to copy: ', err);
        });
    },

    async loadData() {
      try {
        const body = {
          quota_owner: 'OA',
          quota_type: 'sub_quota',
        };

        const response = await this.$api.post('/ZaloZnsLog/GetAssetOA', body, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        this.filterStatusAvailabel = response.data.filter(
          (item) => item.status === 'used',
        );
        this.filterStatus = response.data.filter(
          (item) => item.status === 'available',
        );
      } catch (error) {
        console.log(error);
      }
    },

    show() {
      this.$refs['zalo-edit-modal'].show();
    },
    hide() {
      this.$refs['zalo-edit-modal'].hide();
    },

    async getDetailGroupOA(group_id) {
      const body = {
        group_id: group_id,
      };

      const response = await this.$api.post('/ZaloZnsLog/DetailGroup', body, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      this.form.group_name = response.data;
    },

    async handleSubmit() {
      const groupName = this.form.group_name;
      const groupDescription = this.form.group_description.value;
      const groupDescriptionName = this.form.group_description.text;

      this.isSubmitting = true;

      if (!groupName || !groupDescription) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: 'Tên nhóm và loại nhóm không được để trống.',
        });
        this.isSubmitting = false;
        return;
      }

      if (groupName.trim().length >= 200) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: 'Vui lòng chọn tên dưới 200 ký tự',
        });
        this.isSubmitting = false;
        return;
      }

      if (typeof groupName !== 'string' || groupName.trim().length < 10) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: 'Tên nhóm là text và có ít nhất 10 ký tự.',
        });
        this.isSubmitting = false;
        return;
      }
      this.assginZaloAdmin(this.groupItem.group_id, groupDescriptionName);
      this.$emit('call-refresh-list');
    },

    async assginZaloAdmin(group_id, group_description) {
      try {
        this.$store.commit('context/setLoading', true);

        this.form.lock_send_msg = this.lockmessage === 'common';
        this.form.group_avatar =
          'https://hcm03.vstorage.vngcloud.vn/v1/AUTH_e575ba7d5712488190aef827d0bcface/Image%20Website/logo-diab-offical';

        if (this.member_user_ids.size === 0) {
          for (let key in this.coach_id) {
            this.member_user_name.add(this.coach_id[key].fullNameSearch);
            this.member_user_ids.add(this.coach_id[key].zaloUserId);
          }
        }

        const zaloChooseCoach =
          'Type Group: ' +
          group_description +
          '\nHealth Coach: ' +
          [...this.member_user_name] +
          '\nIDs: ' +
          [...this.member_user_ids];
        const body = {
          group_id: group_id,
          group_name: this.form.group_name,
          lock_send_msg: this.form.lock_send_msg,
          join_appr: this.form.join_appr,
          enable_msg_history: this.form.enable_msg_history,
          enable_link_join: this.form.enable_link_join,
          group_description: zaloChooseCoach,
          member_user_ids: [...this.member_user_ids],
        };
        await this.$api.post('/ZaloZnsLog/UpdateZaloGroupAfterCreate', body, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        this.$toastr.s({
          title: 'Thành công!',
          msg: 'Nhóm zalo được cập nhật thành công.',
        });
      } catch {
        this.$toastr.e({
          title: 'Thất bại!',
          msg: 'Nhóm zalo chưa được cập nhật.',
        });
      } finally {
        this.handleSuccessOk();
        this.$store.commit('context/setLoading', false);
      }
    },

    handleCancelSuccess() {
      this.$refs['success-modal'].hide();
      this.$refs['zalo-modal'].hide();
      this.resetModal();
      this.hide();
    },
    handleCancel() {
      this.hide();
      this.resetModal();
    },
    handleSuccessOk() {
      this.hide();
      this.resetModal();
    },
    resetModal() {
      // Reset modal state if needed
      // this.form.group_name = '';
      // this.form.group_description = '';
      this.isSubmitting = false;
    },
  },
};
</script>
<style scoped>
.no-footer .modal-footer {
  display: none;
}
.group-name {
  display: inline-block; 
  max-width: 500px; 
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.bold-label {
  font-weight: bold;
  color: #333; 
  text-align: left; 
}

button.btn.btn-secondary.collapsed {
  margin: -15px;
}
button.btn.btn-secondary.not-collapsed {
  margin: -15px;
}
</style>
